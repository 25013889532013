const BACKUPS = new Set([
  1084911, 1084916, 1084919, 1084914, 1084923, 1084926, 1084929, 1084932,
  1084912, 1084917, 1084920, 1084915, 1084924, 1084927, 1084930, 1084933,
  1084913, 1084918, 1084921, 1084922, 1084925, 1084928, 1084931, 1084934,
  1084935, 1084938, 1084940, 1084937, 1084943, 1084945, 1084947, 1084949,
  1084936, 1084939, 1084941, 1084942, 1084944, 1084946, 1084948, 1084950,
  1084951, 1084954, 1084956, 1084953, 1084959, 1084961, 1084963, 1084965,
  1084952, 1084955, 1084957, 1084958, 1084960, 1084962, 1084964, 1084966,
  1084967, 1084970, 1084972, 1084969, 1084975, 1084977, 1084979, 1084981,
  1084968, 1084971, 1084973, 1084974, 1084976, 1084978, 1084980, 1084982,
  1084983, 1084986, 1084988, 1084985, 1084991, 1084993, 1084995, 1084997,
  1084984, 1084987, 1084989, 1084990, 1084992, 1084994, 1084996, 1084998,
  1084999, 1085001, 1085002, 1085000, 1085003, 1085004, 1085005, 1085006,
]);

const WSS = new Set([
  1068812, 1073665, 1073673, 1073675, 1073669, 1073677, 1073679, 1073681,
  1073683, 1073667, 1073674, 1073676, 1073672, 1073678, 1073680, 1073682,
  1073684, 1068442, 1068444, 1068446, 1068448, 1068450, 1068443, 1068445,
  1068447, 1068449, 1068451, 1068772, 1068775, 1068778, 1068781, 1068784,
  1068773, 1068776, 1068779, 1068782, 1068785, 1068774, 1068777, 1068780,
  1068783, 1068786, 1068787, 1068789, 1068791, 1068793, 1068795, 1068788,
  1068790, 1068792, 1068794, 1068796, 1068797, 1068799, 1068801, 1068803,
  1068805, 1068798, 1068800, 1068802, 1068804, 1068806, 1068807, 1068808,
  1068809, 1068810, 1068811, 1068452, 1068457, 1068460, 1068455, 1068464,
  1068467, 1068470, 1068473, 1211087, 1211090, 1211093, 1211096, 1211099,
  1068454, 1068459, 1068462, 1068463, 1068466, 1068469, 1068472, 1068475,
  1068477, 1068480, 1068482, 1068483, 1068485, 1068487, 1068489, 1068491,
  1068493, 1068496, 1068498, 1068499, 1068501, 1068503, 1068505, 1068507,
  1068509, 1068512, 1068514, 1068515, 1068517, 1068519, 1068521, 1068523,
  1068525, 1068528, 1068530, 1068531, 1068533, 1068535, 1068537, 1068539,
  1211089, 1211092, 1211095, 1211098, 1211101, 1068541, 1068544, 1068546,
  1068547, 1068549, 1068551, 1068553, 1068555, 1068557, 1068560, 1068562,
  1068563, 1068565, 1068567, 1068569, 1068571, 1068573, 1068576, 1068578,
  1068579, 1068581, 1068583, 1068585, 1068587, 1068589, 1068592, 1068594,
  1068595, 1068597, 1068599, 1068601, 1068603, 1068605, 1068608, 1068610,
  1068611, 1068613, 1068615, 1068617, 1068619, 1211103, 1211105, 1211107,
  1211109, 1211111, 1068621, 1068624, 1068626, 1068627, 1068629, 1068631,
  1068633, 1068635, 1068637, 1068640, 1068642, 1068643, 1068645, 1068647,
  1068649, 1068651, 1068653, 1068656, 1068658, 1068659, 1068661, 1068663,
  1068665, 1068667, 1068669, 1068672, 1068674, 1068675, 1068677, 1068679,
  1068681, 1068683, 1068685, 1068688, 1068690, 1068691, 1068693, 1068695,
  1068697, 1068699, 1211113, 1211115, 1211117, 1211119, 1211121, 1068701,
  1068704, 1068706, 1068707, 1068709, 1068711, 1068713, 1068715, 1068717,
  1068720, 1068722, 1068723, 1068725, 1068727, 1068729, 1068731, 1068733,
  1068736, 1068738, 1068739, 1068741, 1068743, 1068745, 1068747, 1068749,
  1068752, 1068754, 1068755, 1068757, 1068759, 1068761, 1068763, 1068453,
  1068458, 1068461, 1068456, 1068465, 1068468, 1068471, 1068474, 1211088,
  1211091, 1211094, 1211097, 1211100, 1068476, 1068479, 1068481, 1068478,
  1068484, 1068486, 1068488, 1068490, 1068492, 1068495, 1068497, 1068494,
  1068500, 1068502, 1068504, 1068506, 1068508, 1068511, 1068513, 1068510,
  1068516, 1068518, 1068520, 1068522, 1068524, 1068527, 1068529, 1068526,
  1068532, 1068534, 1068536, 1068538, 1068540, 1068543, 1068545, 1068542,
  1068548, 1068550, 1068552, 1068554, 1211102, 1211104, 1211106, 1211108,
  1211110, 1068556, 1068559, 1068561, 1068558, 1068564, 1068566, 1068568,
  1068570, 1068572, 1068575, 1068577, 1068574, 1068580, 1068582, 1068584,
  1068586, 1068588, 1068591, 1068593, 1068590, 1068596, 1068598, 1068600,
  1068602, 1068604, 1068607, 1068609, 1068606, 1068612, 1068614, 1068616,
  1068618, 1068620, 1068623, 1068625, 1068622, 1068628, 1068630, 1068632,
  1068634, 1211112, 1211114, 1211116, 1211118, 1211120, 1068636, 1068639,
  1068641, 1068638, 1068644, 1068646, 1068648, 1068650, 1068652, 1068655,
  1068657, 1068654, 1068660, 1068662, 1068664, 1068666, 1068668, 1068671,
  1068673, 1068670, 1068676, 1068678, 1068680, 1068682, 1068684, 1068687,
  1068689, 1068686, 1068692, 1068694, 1068696, 1068698, 1068700, 1068703,
  1068705, 1068702, 1068708, 1068710, 1068712, 1068714, 1211122, 1211123,
  1211124, 1211125, 1211126, 1068716, 1068719, 1068721, 1068718, 1068724,
  1068726, 1068728, 1068730, 1068732, 1068735, 1068737, 1068734, 1068740,
  1068742, 1068744, 1068746, 1068748, 1068751, 1068753, 1068750, 1068756,
  1068758, 1068760, 1068762, 1068764, 1068766, 1068767, 1068765, 1068768,
  1068769, 1068770, 1068771, 1071623, 1071628, 1071631, 1071626, 1071635,
  1071638, 1071641, 1071644, 1071624, 1071629, 1071632, 1071627, 1071636,
  1071639, 1071642, 1071645, 1071625, 1071630, 1071633, 1071634, 1071637,
  1071640, 1071643, 1071646, 1071647, 1071650, 1071652, 1071649, 1071655,
  1071657, 1071659, 1071661, 1071648, 1071651, 1071653, 1071654, 1071656,
  1071658, 1071660, 1071662, 1071663, 1071666, 1071668, 1071665, 1071671,
  1071673, 1071675, 1071677, 1071664, 1071667, 1071669, 1071670, 1071672,
  1071674, 1071676, 1071678, 1071679, 1071682, 1071684, 1071681, 1071687,
  1071689, 1071691, 1071693, 1071680, 1071683, 1071685, 1071686, 1071688,
  1071690, 1071692, 1071694, 1071695, 1071698, 1071700, 1071697, 1071703,
  1071705, 1071707, 1071709, 1071696, 1071699, 1071701, 1071702, 1071704,
  1071706, 1071708, 1071710, 1071711, 1071714, 1071716, 1071713, 1071719,
  1071721, 1071723, 1071725, 1071712, 1071715, 1071717, 1071718, 1071720,
  1071722, 1071724, 1071726, 1071727, 1071730, 1071732, 1071729, 1071735,
  1071737, 1071739, 1071741, 1071728, 1071731, 1071733, 1071734, 1071736,
  1071738, 1071740, 1071742, 1071743, 1071746, 1071748, 1071745, 1071751,
  1071753, 1071755, 1071757, 1071744, 1071747, 1071749, 1071750, 1071752,
  1071754, 1071756, 1071758, 1071759, 1071762, 1071764, 1071761, 1071767,
  1071769, 1071771, 1071773, 1071760, 1071763, 1071765, 1071766, 1071768,
  1071770, 1071772, 1071774, 1071775, 1071778, 1071780, 1071777, 1071783,
  1071785, 1071787, 1071789, 1071776, 1071779, 1071781, 1071782, 1071784,
  1071786, 1071788, 1071790, 1071791, 1071794, 1071796, 1071793, 1071799,
  1071801, 1071803, 1071805, 1071792, 1071795, 1071797, 1071798, 1071800,
  1071802, 1071804, 1071806, 1071807, 1071809, 1071810, 1071808, 1071811,
  1071812, 1071813, 1071814, 1071815, 1071818, 1071821, 1071824, 1071827,
  1071816, 1071819, 1071822, 1071825, 1071828, 1071817, 1071820, 1071823,
  1071826, 1071829, 1071830, 1071832, 1071834, 1071836, 1071838, 1071831,
  1071833, 1071835, 1071837, 1071839, 1071840, 1071841, 1071842, 1071843,
  1071844, 1193551, 1193543, 1193548, 1193546, 1193555, 1193558, 1193561,
  1193564, 1193589, 1193584, 1193587, 1193590, 1193592, 1193594, 1193596,
  1193598, 1193653, 1193648, 1193651, 1193654, 1193656, 1193658, 1193660,
  1193662, 1193605, 1193600, 1193603, 1193606, 1193608, 1193610, 1193612,
  1193614, 1193621, 1193616, 1193619, 1193622, 1193624, 1193626, 1193628,
  1193630, 1193553, 1193545, 1193550, 1193554, 1193557, 1193560, 1193563,
  1193566, 1193573, 1193568, 1193571, 1193574, 1193576, 1193578, 1193580,
  1193582, 1193637, 1193632, 1193635, 1193638, 1193640, 1193642, 1193644,
  1193646, 1193552, 1193544, 1193549, 1193547, 1193556, 1193559, 1193562,
  1193565, 1193604, 1193599, 1193602, 1193601, 1193607, 1193609, 1193611,
  1193613, 1193666, 1193663, 1193665, 1193664, 1193667, 1193668, 1193669,
  1193670, 1193620, 1193615, 1193618, 1193617, 1193623, 1193625, 1193627,
  1193629, 1193636, 1193631, 1193634, 1193633, 1193639, 1193641, 1193643,
  1193645, 1193572, 1193567, 1193570, 1193569, 1193575, 1193577, 1193579,
  1193581, 1193588, 1193583, 1193586, 1193585, 1193591, 1193593, 1193595,
  1193597, 1193652, 1193647, 1193650, 1193649, 1193655, 1193657, 1193659,
  1193661, 1274053, 1274057, 1274059, 1274055, 1274061, 1274063, 1274065,
  1274067, 1274069, 1274071, 1274073, 1274075, 1274077, 1274078, 1274076,
  1274074, 1274072, 1274070, 1274068, 1274066, 1274064, 1274062, 1274056,
  1274060, 1274058, 1274054, 1317181, 1317190, 1317193, 1317196, 1317199,
  1317189, 1317192, 1317195, 1317198, 1317201, 1317250, 1317252, 1317254,
  1317256, 1317258, 1317276, 1317278, 1317280, 1317282, 1317284, 1317218,
  1317181, 1317183, 1317185, 1317189, 1317190, 1317192, 1317193, 1317195,
  1317196, 1317198, 1317199, 1317201, 1317202, 1317204, 1317205, 1317207,
  1317208, 1317210, 1317211, 1317213, 1317214, 1317216, 1317247, 1317250,
  1317252, 1317254, 1317256, 1317258, 1317260, 1317262, 1317264, 1317266,
  1317268, 1317273, 1317276, 1317278, 1317280, 1317282, 1317284, 1317286,
  1317288, 1317290, 1317292, 1317294, 1317181, 1317183, 1317185, 1317189,
  1317190, 1317192, 1317193, 1317195, 1317196, 1317198, 1317199, 1317201,
  1317202, 1317204, 1317205, 1317207, 1317208, 1317210, 1317211, 1317213,
  1317214, 1317216, 1317247, 1317250, 1317252, 1317254, 1317256, 1317258,
  1317260, 1317262, 1317264, 1317266, 1317268, 1317273, 1317276, 1317278,
  1317280, 1317282, 1317284, 1317286, 1317288, 1317290, 1317292, 1317294,
  1317180, 1317178, 1524364,
]);

const STANDARD_SSL = new Set([
  1130, 1131, 1132, 1152, 1153, 1154, 1706, 1707, 2676, 2677, 2678, 2679, 3601,
  3602, 3603, 3604, 3605, 3606, 3608, 3614, 3615, 3616, 3617, 3618, 3619, 3645,
  3646, 3674, 3675, 3676, 3681, 3682, 3683, 5710, 5711, 5712, 5713, 5714, 5715,
  5716, 5717, 5718, 5719, 5720, 5721, 5722, 5723, 5724, 5725, 5726, 5727, 5728,
  5729, 5730, 5731, 5732, 5733, 5790, 5791, 5792, 5793, 5794, 5795, 5796, 5797,
  5798, 5799, 5800, 5801, 5802, 5803, 5804, 5805, 5806, 5807, 5808, 5809, 5810,
  5811, 13601, 13602, 13603, 13604, 13605, 13606, 13614, 13615, 13616, 13617,
  13618, 13619, 13634, 13635, 13636, 13645, 13646, 13674, 13675, 13676, 13681,
  13682, 13683, 13684, 15710, 15711, 15712, 15713, 15714, 15715, 15716, 15717,
  15718, 15719, 15720, 15721, 15722, 15723, 15724, 15725, 15726, 15727, 15728,
  15729, 15730, 15731, 15732, 15733, 15790, 15791, 15792, 15793, 15794, 15796,
  15797, 15798, 15799, 15800, 15801, 15804, 15806, 15807, 15808, 15809, 15810,
  15811, 15813, 125149, 125150, 273601, 273602, 273603, 273604, 273605, 273606,
  273614, 273615, 273616, 273617, 273618, 273619, 283601, 283602, 283603,
  283604, 283605, 283606, 283614, 283615, 283616, 283617, 283618, 283619,
  283633, 283634, 283635, 543594, 543595, 543596, 543597, 543598, 543599,
  543600, 543601, 543603, 543605, 543606, 543607, 543608, 543609, 543611,
  543613, 543617, 543619, 543620, 543621, 543623, 566312,
]);

const MSSL = new Set([
  1200205, 1200206, 1200207, 1200208, 1200209, 1200210, 1200211, 1200212,
  1200213, 1200214, 1200215, 1200216, 1221599, 1221600, 1221601, 1221602,
  1221603, 1221604, 1221605, 1221606, 1221607, 1221608, 1222337, 1222338,
  1222339, 1222340, 1222341, 1222342, 1222343, 1222344, 1222345, 1222346,
  1222347, 1222348, 1222349, 1222350, 1222351, 1222352, 1222353, 1222354,
  1222355, 1222356, 1222357, 1222358, 1222359, 1222360, 1222361, 1222362,
  1222363, 1222364, 1222365, 1222366, 1222367, 1222368, 1222369, 1222370,
  1222371, 1222372, 1222373, 1222374, 1222375, 1222376, 1222377, 1222378,
  1222379, 1222380, 1222381, 1222382, 1222383, 1222384, 1222385, 1222386,
  1222387, 1222388, 1222389, 1222390, 1222391, 1222392, 1222393, 1222394,
  1222395, 1222396, 1222397, 1222398, 1222399, 1222400, 1222401, 1222402,
  1222403, 1222404, 1222405, 1222406, 1222407, 1222408, 1222409, 1222410,
  1222411, 1222412, 1222413, 1222414, 1222415, 1222416, 1222417, 1222418,
  1222419, 1222420, 1222421, 1222422, 1222423, 1222424, 1222425, 1222426,
  1222427, 1222428, 1222429, 1222430, 1222431, 1222432, 1222433, 1222434,
  1222435, 1222436, 1222437, 1222438, 1222439, 1222440, 1222441, 1222442,
  1222453, 1222454, 1222455, 1222456, 1222457, 1222458, 1222459, 1222460,
  1222461, 1222462, 1222463, 1222464, 122246, 1211081, 1211082, 1211083,
  1211084, 1211085, 1211086, 1303514, 1303515, 1303519, 1303531, 1303554,
  1303563, 1303538, 1303547, 1303566, 1303572, 1494083, 1494084, 1494085,
  1522424, 1651018, 1651021, 1651024, 1651027, 1651030, 1651019, 1651022,
  1651025, 1651028, 1651031, 1651026, 1651029, 1651032, 1651037, 1651039,
  1651041, 1651034, 1651036, 1651038, 1651040, 1651042, 1651043, 1651045,
  1651047, 1651049, 1651051, 1651044, 1651046, 1651048, 1651050, 1651052,
  1651053, 1651055, 1651057, 1651059, 1651061, 1651054, 1651056, 1651058,
  1651060, 1651062, 1651063, 1651064, 1651065, 1651066, 1651067, 1651020,
  1651023, 1651033, 1651035, 1680623, 1680626, 1680629, 1680632, 1680635,
  1680625, 1680628, 1680631, 1680634, 1680637, 1680639, 1680641, 1680643,
  1680645, 1680647, 1680624, 1680627, 1680630, 1680633, 1680636, 1680638,
  1680640, 1680642, 1680644, 1680646, 1680648, 1680649, 1680650, 1680651,
  1680652,
]);

const SAN5 = new Set([
  1200209, 1200210, 1200211, 1200212, 1222363, 1222364, 1222365, 1222366,
  1222367, 1222368, 1222369, 1222370, 1222371, 1222372, 1222413, 1222414,
  1222415, 1222416, 1222417, 1222418, 1222419, 1222420, 1222421, 1222422,
  1222453, 1222454, 1222455, 1222456, 1222457, 1222458,
]);

const SAN10 = new Set([
  1221599, 1221600, 1221601, 1221602, 1221603, 1221604, 1221605, 1221606,
  1221607, 1221608, 1222373, 1222374, 1222375, 1222376, 1222377, 1222378,
  1222379, 1222380, 1222381, 1222382, 1222423, 1222424, 1222425, 1222426,
  1222427, 1222428, 1222429, 1222430, 1222431, 1222432,
]);

const SAN15 = new Set([
  1222343, 1222344, 1222345, 1222346, 1222347, 1222348, 1222349, 1222350,
  1222351, 1222352, 1222383, 1222384, 1222385, 1222386, 1222387, 1222388,
  1222389, 1222390, 1222391, 1222392, 1222433, 1222434, 1222435, 1222436,
  1222437, 1222438, 1222439, 1222440, 1222441, 1222442,
]);

const SAN25 = new Set([1211083, 1211084]);

const DOMAIN_SECURITY_ADDONS = new Set([
  1307404, 1307406, 1307303, 1307309, 1307304,
]);

const ULTIMATE_MWP = new Set([
  580995, 580998, 581000, 581001, 581003, 581005, 581007, 581009, 581011,
  581012, 581015, 581017, 581014, 581020, 581022, 581024, 581026, 581028,
  1317516, 1317520, 1317522, 1317518, 1317524, 1317526, 1317528, 1317530,
  1317532, 1317517, 1317521, 1317523, 1317519, 1317525, 1317527, 1317529,
  1317531, 1317533,
]);

const SSL_OV_EV = new Set([
  3601, 3602, 3603, 3614, 3615, 3616, 3645, 3646, 5790, 5791, 5792, 5793, 5794,
  5795, 5796, 5797, 5798, 5799, 5800, 5801, 5802, 5803, 5804, 5805, 5806, 5807,
  5808, 5809, 5810, 5811, 5812, 5813, 13601, 13602, 13603, 13614, 13615, 13616,
  13645, 13646, 15790, 15791, 15792, 15793, 15794, 15795, 15796, 15797, 15798,
  15799, 15800, 15801, 15802, 15804, 15805, 15806, 15807, 15808, 15809, 15811,
  543594, 543595, 543596, 543597, 543598, 543599, 543600, 543601, 543602,
  543603, 543604, 543605, 543606, 543607, 543608, 543609, 543610, 543611,
  543612, 543613, 543614, 543615, 543616, 543617, 543618, 543619, 543620,
  543621, 543622, 543623, 543624, 543625,
]);

const SSL_DV = new Set([
  1706, 1707, 2676, 2677, 2678, 2679, 2680, 2681, 2682, 2683, 3604, 3605, 3606,
  3607, 3608, 3609, 3610, 3611, 3612, 3613, 3674, 3675, 3676, 3677, 3678, 13604,
  13605, 13606, 13607, 13608, 13609, 13610, 13611, 13612, 13613, 13674, 13675,
  273604, 273605, 273606, 273607, 273608, 273609, 273610, 273611, 273612,
  273613, 283604, 283605, 373602, 383602, 2003604, 2003605, 2003606, 2003607,
  2003608, 2003609, 2003610, 2003611, 2003612, 2003613, 2013604, 2013605,
  2013606, 2013607, 2013608, 2013609, 2013610, 2013611, 2013612, 2013613,
]);

export {
  BACKUPS,
  WSS,
  STANDARD_SSL,
  MSSL,
  SAN5,
  SAN10,
  SAN15,
  SAN25,
  DOMAIN_SECURITY_ADDONS,
  ULTIMATE_MWP,
  SSL_OV_EV,
  SSL_DV,
};
