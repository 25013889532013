const isRealDomain = (domain) => {
  const stagingDomains = [
    'myftpupload.com',
    'accessdomain.com',
    'hostedresource.net',
    'new account',
  ];

  for (const d of stagingDomains) {
    if (domain.endsWith(d)) {
      return false;
    }
  }

  return true;
};

const normalizeDomain = (domain) => {
  return domain.toLowerCase().trim();
};

const processHostingProducts = (prods) => {
  const hostingProducts = [];

  prods.forEach((subscription) => {
    if (typeof subscription.externalId !== 'undefined') {
      if (
        subscription.externalId.length > 0 &&
        subscription.label?.length > 0
      ) {
        // Need to check and see if the label is a domain, newAccount, or WP staging domain
        if (subscription.label.includes(',')) {
          var hMultiDomain = subscription.label.split(',');
          hMultiDomain.forEach((multiDomain) => {
            if (isRealDomain(normalizeDomain(multiDomain))) {
              hostingProducts.push({
                domain: normalizeDomain(multiDomain),
                externalId: subscription.externalId,
                type: subscription.product.productGroupKey,
                namespace: subscription.product.namespace,
                productLabel: subscription.product.label,
              });
            }
          });
        } else if (isRealDomain(normalizeDomain(subscription.label))) {
          hostingProducts.push({
            domain: normalizeDomain(subscription.label),
            externalId: subscription.externalId,
            type: subscription.product.productGroupKey,
            namespace: subscription.product.namespace,
            productLabel: subscription.product.label,
          });
        } else if (subscription.product.productGroupKey === 'servers') {
          hostingProducts.push({
            domain:
              subscription.label !== 'New Account'
                ? normalizeDomain(subscription.label)
                : subscription.externalId,
            externalId: subscription.externalId,
            type: subscription.product.productGroupKey,
            namespace: subscription.product.namespace,
            productLabel: subscription.product.label,
          });
        }
      }
    }
  });

  return hostingProducts;
};

const processMSSLProducts = (msslSubs) => {
  // Workaround for MSSL Credits not having a Label
  const modifiedMsslSubs = [];

  msslSubs.map((p) => {
    const tempMsslSub = { ...p };

    tempMsslSub.customLabel = 'mssl_cert_standard';

    if (p.product.label.includes('SAN') || p.product.label.includes('UCC')) {
      tempMsslSub.customLabel = 'mssl_cert_san';
    }

    if (p.product.label.includes('Wildcard')) {
      tempMsslSub.customLabel = 'mssl_cert_wildcard';
    }

    modifiedMsslSubs.push(tempMsslSub);
  });

  return modifiedMsslSubs;
};

const processWebsiteBuilderProducts = (wbSubs) => {
  return wbSubs.map((p) => {
    return {
      domain: p.label,
      externalId: p.externalId,
      type: p.product.productGroupKey,
      namespace: p.product.namespace,
      productLabel: p.product.label,
    };
  });
};

export {
  processHostingProducts,
  processMSSLProducts,
  processWebsiteBuilderProducts,
};
